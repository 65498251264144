import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slides", "frame"]

  selectImage(event) {
    const index = parseInt(event.currentTarget.dataset.index, 10);
    const slideWidth = this.frameTarget.offsetWidth; // Get the width of the visible frame
    const offset = slideWidth * index; // Calculate the offset
    this.slidesTarget.style.transform = `translateX(-${offset}px)`; // Apply the transformation
  }
}